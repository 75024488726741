import axios from "axios";
import { isEqual } from "lodash";
import { ToastProgrammatic as Toast } from "buefy";
import ListModelBase from "@/apps/core/models/listModelBase.js";

const modelName = "kdList";
const modelPath = "/programstudi/targetdiagnosis/batch/";

class KDList extends ListModelBase {
  constructor() {
    super(modelName, modelPath);
    this.inlineRequiredFields = ["target"];
  }

  initObservables() {
    super.initObservables();
    this.validity = { validated: false };
    this.observables.validity = this.validity;
    this.observables.errorList = [];
  }

  calcValidity() {
    const errorList = this.observables.errorList;
    let validated = false;
    for (const kd of this.observables.kdList) {
      let vals = Object.values(kd);
      validated = vals.reduce((res, val) => res && !!val, true);
      if (validated) {
        break;
      }
    }

    // check inline validity
    inline:
    for (const iError of errorList) {
      for (const val of Object.values(iError)) {
        let validVal = val === "";
        if (!validVal) {
          validated = false;
          break inline;
        }
      }
    }

    this.validity.validated = validated;
  }

  validateInline(idx) {
    let inlineList = this.observables[this.modelName];
    let iModel = inlineList[idx];
    let errorMap = this.observables.errorList[idx];

    let fieldDataList = [];
    let fieldNoDataList = [];
    for (const key of Object.keys(errorMap)) {
      if (iModel[key] != null) {
        fieldDataList.push(key);
      } else {
        fieldNoDataList.push(key);
      }
    }

    fieldNoDataList.sort();
    fieldDataList.sort();
    const allOK = isEqual(fieldDataList, this.inlineRequiredFields) ||
    isEqual(fieldNoDataList, this.inlineRequiredFields);

    for (const key of Object.keys(errorMap)) {
      if (allOK) {
        errorMap[key] = "";
      } else {
        errorMap[key] = iModel[key] ? "" : "Harus diisi.";
      }
    }
    this.calcValidity();
  }

  getLoadData(data) {
    let targetList = JSON.parse(JSON.stringify(data));
    this.observables.errorList = [];
    for (let el of targetList) {
      el.target = null;
      this.observables.errorList.push({target: ""})
    }
    return targetList;
  }

  getPayload() {
    let data = JSON.parse(JSON.stringify(this.observables[this.modelName]));
    let payLoad = { data_set: [] };
    for (let el of data) {
      let targetKD = {
        angkatan: el.angkatan.id,
        diagnosis: el.diagnosis.id,
        target: el.target
      };
      payLoad.data_set.push(targetKD);
    }
    return payLoad;
  }

  updateErrorFields(respErrorList) {
    /* digunakan untuk load error dari response */
    this.validity.validated = false;
    this.observables.errorList = respErrorList;
  }

  create(onSaved) {
    const data = this.getPayload();
    axios.post(this.apiURL, data)
      .then((response) => {
        this.validity.validated = false;
        Toast.open("Data berhasil disimpan.");
        if (onSaved) {
          onSaved(response.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {  // Perlu handle 403 juga
          this.updateErrorFields(error.response.data);
        }
      });
  }

  // reload() {
  //   this.load();
  //   this.validity.validated = false;
  // }
}

export default KDList;