<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">TARGET DIAGNOSIS</p>
          <a
            v-if="isAdminBagian"
            class="card-header-icon"
            :disabled="!isValid"
            @click.stop.prevent="saveObjects()"
          >
            <b-icon
              v-if="kdList.length > 0"
              icon="save"
              size="is-small"
              class="has-text-light"
            />
          </a>
        </header>
        <div class="card-content table-card-content">
          <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
          <div class="box-container">
            <small v-if="kdList.length > 0" class="page-info is-pulled-right"
              ><strong>{{ pagination.startRowNum }}</strong> -
              <strong>{{ pagination.startRowNum + kdList.length - 1 }}</strong>
              dari <strong>{{ pagination.total }}</strong></small
            >
            <div class="cleardiv"></div>
            <box
              v-for="(kd, index) in kdList"
              :key="kd.id"
              :title="kd.diagnosis.nama"
              :subtitle1="kd.sub_departemen"
            >
              <template v-slot:date>
                <span class="is-pulled-right has-text-grey">
                  <small class="tanggal">{{ kd.angkatan.mulai }}</small>
                </span>
              </template>
              <template v-slot:content>
                <template v-if="isAdminBagian">
                  <div class="target-container">
                    <b-field :type="getType(index, 'target')">
                      <b-numberinput
                        size="is-small"
                        min="0"
                        v-model="kd.target"
                        placeholder="Target"
                        :controls="false"
                        @input="validateInline(index)"
                      ></b-numberinput>
                    </b-field>
                  </div>
                </template>
                <template v-else>
                  <b-tag>Target belum diisi</b-tag>
                </template>
              </template>
            </box>
            <box v-if="kdList.length == 0">
              <template v-slot:content>
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>Tidak ada data.</p>
                  </div>
                </section>
              </template>
            </box>
          </div>

          <b-pagination
            v-if="pagination.showPagination"
            :total="pagination.total"
            :per-page="pagination.limit"
            :current.sync="pagination.page"
            order="is-centered"
            icon-pack="fas"
            @change="pageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import fetchCreatedMixin from "@/apps/core/mixins/fetchCreatedMixin";
import paginationMixin from "@/apps/core/mixins/paginationMixin";
import KDList from "../models/targetDiagBatchCreate.js";
import { has } from "lodash";

export default {
  name: "TargetDiagBatchCreate",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
  },
  data() {
    this.kdListMdl = new KDList();
    this.listMdl = this.kdListMdl; // bikin alias
    let obv = this.kdListMdl.getObservables();
    return obv;
  },
  computed: {
    isValid() {
      return this.validity.validated;
    },
    ...mapGetters("accounts", ["isAdminBagian"]),
  },
  methods: {
    getType(idx, field) {
      if (has(this.errorList[idx], field)) {
        return this.errorList[idx][field] ? "is-danger" : "";
      }
      return "";
    },
    needConfirmation() {
      let kdBelumSet = this.kdList.filter(
        (d) => d.target != null
      );
      return kdBelumSet.length > 0;
    },
    validateInline(idx) {
      this.kdListMdl.validateInline(idx);
    },
    saveObjects() {
      if (!this.needConfirmation()) return;
      this.listMdl.create(this.updatePage);
    },
  },
  mixins: [onlineCheckMixin, fetchCreatedMixin, paginationMixin],
};
</script>


<style scoped lang="scss">
@import "@/apps/core/assets/scss/listview.scss";
.tanggal {
  font-size: 0.8rem !important;
}

.target-container {
  float: left;
  margin-right: 5px;
  max-width: 120px;
}
</style>
